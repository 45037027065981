/* HEADER STYLES
   ----------------------------- */

.userDropdown {

  &-wrapper {
    display: inline-block;
    margin-right: 15px;
    margin-left: 10px;
  }

  &-company {
    font-size: 18px;
    text-transform: capitalize;
    line-height: 48px;
    margin-bottom: 0px;
  }

	&-name {
	  font-size: 14px;
    text-transform: capitalize;
    line-height: 32px;
    margin-bottom: 0px;
	}

  &-role {
    font-size: 10px;
    text-transform: capitalize;
    line-height: 4px;
    text-align: right;
    margin-bottom: 0px;
  }
}
