/*!

 =========================================================
 * Vibe - Dashboard Theme - v1.0.0
 =========================================================

 * Product: http://www.nicedash.com/dashboards/vibe
 * Copyright 2018 Nice Dash (http://www.nicedash.com)

 =========================================================

 */
 