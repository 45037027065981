// Custom scss classes

@import './components/index';

.btn-link {
  text-decoration: none;
}

.btn.btn-secondary {
  background: #FFF;
  box-shadow: none;

  &:hover {
    background: #FFF;
    box-shadow: none;
  }

  &:focus {
    background: #FFF;
    box-shadow: none;
  }
}

.btn.btn-primary {
  background-color: #6FC0F9;
  border: 1px solid #6FC0F9;

  &:hover {
    background-color: #6FC0F9;
    border: 1px solid #6FC0F9;
  }

  &:focus {
    background-color: #6FC0F9;
    border: 1px solid #6FC0F9;
  }
}

body {
  padding-right: 0 !important;
}

#react-tiny-popover-container {
  z-index: 999,
}

.highlighted-table {
  .rs-table-cell-header {
    .rs-table-cell-content {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 8px;
    }
  }

  .rs-table-column-group-header-content {
    // text-align: center;
  }

  .rs-table-cell-content {
    padding: 0;
  }

  .rs-table-cell-wrap {
    height: 100%;
  }

  &-highlight {
    background-color: #E3F6FF;
    padding: 8px;
    height: 100%;
  }

  &-default {
    background-color: #ffffff;
    padding: 8px;
    height: 100%;
  }
}



.voucher-purchase-step {

  &-1 {
    display: inline-block;
    width: 25%;
    margin-left: 5%;
    margin-right: 6%;
    background-color: #D3E3F2;
    box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
    border-radius: 15px;
    z-index: 10;
    position: relative;
    cursor: pointer;
    z-index: 300;
  }

  &-2 {
    display: inline-block;
    width: 25%;
    margin-right: 7%;
    background-color: #D3E3F2;
    box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
    border-radius: 15px;
    z-index: 10;
    position: relative;
    cursor: pointer;
    z-index: 500;
  }

  &-3 {
    display: inline-block;
    width: 25%;
    background-color: #D3E3F2;
    box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
    border-radius: 15px;
    z-index: 10;
    position: relative;
    cursor: pointer;
    z-index: 300;
  }

  &-active {
    box-shadow: inset 4px 4px 8px #BBCFE2, inset -5px -5px 30px #EEF7FF;
    // box-shadow: -5px -5px 4px rgba(242, 249, 255, 0.8), 5px 5px 4px rgba(173, 189, 204, 0.8);
  }
}