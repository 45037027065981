/* LABEL STYLES
   ----------------------------- */

.label-primary {
    background-color: $colorPrimary!important;
}
.label-success {
	background-color: $colorSuccess!important;
}

.label-warning {
	background-color: $colorWarning!important;
}

.label-info {
	background-color: $colorInfo!important;
}

.label-danger {
	background-color: $colorDanger!important;
}