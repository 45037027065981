

.admin-payments-table {
  .rs-table-cell-header {
    .rs-table-cell-content {
      display: flex;
      //  align-items: center;
      //  justify-content: center;
      padding: 8px;
    }
  }

  .rs-table-column-group-header-content {
    // text-align: center;
  }

  .rs-table-cell-content {
    padding: 0;
  }

  .rs-table-cell-wrap {
    height: 100%;
  }

  &-highlight {
    background-color: #E3F6FF;
    padding: 8px;
    height: 100%;
  }

  &-default {
    background-color: #ffffff;
    padding: 8px;
    height: 100%;
  }
}
