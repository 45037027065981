/* UTILITY STYLES
   ----------------------------- */

.clear {
    display: block;
    overflow: hidden;
}
.full-bleed {
	margin: -$panelPadding;
}
