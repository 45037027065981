.company-api {

  &-button {
    display: inline-block;
    min-width: 100px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &-input {
    display: inline-block;
    width: 50%;
    max-width: 400px;
    min-width: 200px;
  }

  &-label {

  }

  &-text {

  }
}
