/* NAV PILLS STYLES
   ----------------------------- */

.nav-pills > li > a {

	&:hover, &:focus {
		background-color: transparentize($colorPrimary, .9);
	}
}

.nav-item {
	& .logout-button {
		text-decoration: none;
		width: 100%;
		padding-left: 20px; 
		text-align: left;
	}
}
