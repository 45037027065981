/* LIST STYLES
   ----------------------------- */

.list {
	list-style: none;
    padding-left: 0;
    padding-right: 0;
    border-radius: 3px;
}
.list-item {
	display: block;
    position: relative;
    padding: 8px 16px;
}