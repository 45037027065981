

.public-DraftStyleDefault-block {
  margin: 0;
  margin-bottom: 1em;
}

.currency-input {
//  border: 1px solid #DDD;
//  width: 100%;
//  padding: 5px;
//  border-radius: 5px;

  & input {
  //  border: 0;
  //  box-shadow: none;
    display: inline-block;
  //  width: 90%;
  //  padding-top: 0;
  //  margin-top: 0;
  //  padding-bottom: 0;
  //  margin-bottom: 0;
  //  padding-left: 4px;
//    height: auto;
  }

  &-sign {
    position: absolute;
  }

  .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
  }

  .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin-left: -1px;
    position: relative;
    border-radius: 0;
  }

  .input-group-btn-vertical > .btn:first-child {
    border-top-right-radius: 4px;
  }

  .input-group-btn-vertical > .btn:last-child {
    margin-top: -2px;
    border-bottom-right-radius: 4px;
  }
}

.custom-input-placeholder {
  position: absolute; 
  margin-top: 7px;
  margin-left: 15px; 
  pointer-events: none;
}

.custom-primary-button {
  width: 100%;
  // background-color: #4B7CD1 !important;
  background-color: #6FC0F9;
  border: 1px solid #6FC0F9;
}

.custom-modal-button {
  // background-color: #4B7CD1 !important;
  background-color: #6FC0F9;
  border: 1px solid #6FC0F9;
}

.percetage-input {
  border: 1px solid #DDD;
  width: 100%;
  padding: 5px;

  & input {
    border: 0;
    box-shadow: none;
    display: inline-block;
    width: 90%;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-left: 4px;
    padding-right: 4px;
    text-align: right;
    height: auto;
  }

  &-sign {
  }

  .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
  }

  .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin-left: -1px;
    position: relative;
    border-radius: 0;
  }

  .input-group-btn-vertical > .btn:first-child {
    border-top-right-radius: 4px;
  }

  .input-group-btn-vertical > .btn:last-child {
    margin-top: -2px;
    border-bottom-right-radius: 4px;
  }
}

/*
.spinner {
//  width: 100px;
}

.spinner input {
  text-align: right;
}

.input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.spinner input {
  padding-right: 13px;
}

.spinner .input-group-btn-vertical::before {
  content: "%";
  position: absolute;
  right: 0px;
  top: 7px;
}  */
