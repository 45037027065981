.basic-information {

  &-info-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    margin-left: 3px;
    background: #6FC0F9;
    border-radius: 50%;
    font-size: 9px;
    font-weight: 700;
    color: #FFF;
    text-align: center;
  }

  &-middle {
    display: inline-block;
    width: 25%;

    &-companyType {
      display: inline-block;
      width: 40%;
      padding-bottom: 8px;

      &-button {
        width: 100%;
        margin: 1px;

        &-active {
          margin: 0px;
          border: 2px solid #6FC0F9 !important;
          background: transparent !important;
          background-color: transparent !important;
        }
      }
    }
  }

  &-buttons {
    display: inline-block;
    width: 15%;
    color: #6FC0F9;

    &-password {
      height: 70px;
      display: flex;
      align-items: flex-end;
      //  justify-content: flex-end;

      & .btn {
        vertical-align: bottom;
      }
    }
  }

  &-upload {
    display: inline-block;
    width: 15%;

    &-preview {
      padding-top: 0;
      // color: #6FC0F9;
      color: var(--bs-card-color);
      padding-bottom: 0;
      font-size: 14px;
    }

    &-buttons {
      display: inline-block;
      width: 15%;

      & .basic-information-upload-button {
        width: 100%;
        height: 30px;
        display: inline-block;
        max-height: 30px;
        margin-left: 3px;
        font-size: 12px;
        float: right;
        border-radius: 0px 4px 4px 0px;

        & .media {
          width: 18px;
          padding-right: 3px;
          display: inline-block;
        }
      }
    }

    &-middle {
      vertical-align: top;
      font-size: 11px;
      display: inline-block;
      width: 25%;
      height: 30px;
      // border: 1px solid #6FC0F9;
      border: 1px solid #d2d2d2;
      border-radius: 4px 0px 0px 4px;
      border-right: 0;
      line-height: 30px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-label {
    width: 35%;

    &-hasDisplayName {
      padding-left: 4px;
      width: 40%;
    }
  }

  &-check-button {
    padding-top: 5px;
    padding-bottom: 6px;
    max-height: 37px;
    margin-left: 3px;
    display: inline-block;
    float: right;
  }

  &-x-button {
    padding-top: 5px;
    padding-bottom: 6px;
    max-height: 37px;
    margin-left: 3px;
    display: inline-block;
    float: right;
  }

  &-edit-button {
    display: inline-block;
    max-height: 30px;
    padding-top: 0;
    margin-left: 3px;
    padding: 0;
    font-size: 24px;
    float: right;
    color: #6FC0F9;
    //  border: 1px solid black;
  }

  &-upload-button {
  }

  &-placeholder {
    opacity: 1;
    color: #6c757d;
    //  padding-left: 12px;
  }

  &-placeholder-for-image {
    opacity: 1;
    color: #6c757d;
    padding-left: 12px;
  }

  &-compliance {
    &-label {
      width: 45%;
    }

    &-right {
      width: 30%;
      //  display: flex;
      //  align-items: flex-end;
      //  justify-content: flex-end;
    }
  }

  &-row {
    margin-bottom: 5px;
    display: flex;

    &-compliance {
      width: 50%;
    }

    &-hasDisplayName {
      display: block;

      & label {
        //  padding-left: 15px;
      }
    }

    &-companyNumber {
      display: block;
      width: '100%';

      & label {
        //  padding-left: 15px;
      }
    }

    &-companyType {
      // padding-left: 15px;
      margin-bottom: 10px;
      //  padding-left: 15px;

      &-radio {
        padding-left: 15px;

        &-wrapper {
          padding-left: 15px;
          display: inline-block;
          width: 100%;
        }
      }

      & label {
        margin-left: 5px;
      }
    }
  }

  &-changes {
    color: #7f8fa4;


    &-row {
      margin-bottom: 5px;
    }

    &-cancel {
      width: 25px;
      height: 25px;
      font-size: 16px;
      padding: 3px;
      padding-top: 0;
      background-color: red;
      border-radius: 50%;
      font-weight: 700;
      color: #FFF !important;
      cursor: pointer;


      &-button {
        padding: 0;
        text-decoration: none;
      }
    }
  }
}
