.admin-dashboard {
  &-data-fields {
    text-align: center;

    & > .row {
      margin-bottom: 15px;
    }
  }

  &-setup-assistance {
    &-line {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      position: absolute;
      height: 10px;
      top: 56%;
      //  margin-top: 45%;
      //  margin-bottom: 45%;
      background: #D4E9F7;
      z-index: 1;
    }

    &-cicle {
      //  background: #CFE3F7;
      background: #D4E9F7;
      //  background-color: rgb(212, 233, 247);
      width: 45%;
      min-width: 60px;
      max-width: 110px;
      margin-left: auto;
      margin-right: auto;
      //  margin-left: 22.5%;
      //  margin-right: 22.5%;
      //  margin-bottom: 17.5%;
      //  margin-top: 7.5%;
      border-radius: 50%;
      aspect-ratio: 1;
      z-index: 99;

      &-complete {
        background: #7DB84E;
      }

      &-wrapper {
        width: 45%;
        margin-left: auto;
        margin-right: auto;
      }

      &-check {
        width: 100%;
        aspect-ratio: 1;
        z-index: 99;

        &-icon {
          width: 80%;
          height: 85%;
          margin: 0;
          margin-left: 20%;
          margin-bottom: 15%;
          //  margin-left: 25%;
          //  margin-right: 20%;
          //   margin-top: 20%;
          //   margin-bottom: 25%;
          color: #FFFFFF;
          font-size: 80px;
          font-weight: 900;
          stroke-width: 5;
          stroke-linecap: square;
          stroke-linejoin: miter;
          z-index: 99;
        }
      }
    }

    &-info {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      height: 50%;
      max-height: 100px;
      padding: 0;

      &-header {
        margin-bottom: 5px;
        color: #7f8fa4;
        font-size: 28px;
        font-weight: 700;
        text-align: right;
      }

      &-text {
        color: #7f8fa4;
        font-size: 12px;
        font-weight: 400;
        max-width: 190px;

        &-text {
          display: inline-block;
          max-width: 150px;
          text-align: right;
        }

        &-icon {
          display: inline-block;
          width: 36px;
          height: 36px;
          position: relative;
          top: -4px;
          background: #6FC0F9;
          border-radius: 50%;
          font-size: 24px;
          font-weight: 900;
          color: #FFF;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-link {
        color: #7f8fa4;
        font-size: 16px;
        font-weight: 500;
        //  text-decoration: none;
        float: right;

        &:hover {
          color: #7f8fa4;
          //  text-decoration: none;
        }

        &:focus {
          color: #7f8fa4;
          //  text-decoration: none;
        }

        &:active {
          color: #7f8fa4;
          //  text-decoration: none;
        }
      }
    }

    &-card {
      margin-bottom: 15px;
      padding-bottom: 0px;
      padding-top: 25px;
      border: 3px solid rgb(0, 174, 255);

      &-body {
        padding: 10px;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    &-col {
      z-index: 99;
      padding: 0;
      //  border-left: 1px solid rgb(235, 247, 255);
      //  border-right: 1px solid rgb(235, 247, 255);
    }

    &-label {
      text-align: center;
      width: 100%;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      cursor: pointer;

      &-link {
        color: #7f8fa4;
        text-decoration: none;

        &:hover {
          color: #7f8fa4;
          text-decoration: none;
        }

        &:focus {
          color: #7f8fa4;
          text-decoration: none;
        }

        &:active {
          color: #7f8fa4;
          text-decoration: none;
        }
      }
    }
    /* &-indicator {
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 10px;
      display: block;
    } */
  }

  &-analytics {

    &-text {
      padding-top: 7px;
    }
  }

  &-notifications {
    box-shadow: 0px 0px 2px 2px #e6e6e6;
    padding-left: 5px;
    padding-right: 5px;
  }

  &-data-field {
    font-size: 10px;
    height: 100%;
    min-height: 80px;
    box-shadow: 0px 0px 2px 2px #e6e6e6;
    padding: 5px 15px;
    //  margin-bottom: 15px;

    &-h {
      font-size: 11px;
      font-weight: 700;
    }

    & .col {
      padding-left: 1px;
      padding-right: 1px;
    }

    & .col-sm-2 {
      padding-left: 1px;
      padding-right: 1px;
    }

    & .col-sm-3 {
      padding-left: 1px;
      padding-right: 1px;
    }
  }
}
