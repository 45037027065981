
/*
@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

.box2 {
  margin: max(3vw);
  border: 0.35rem solid;

  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
} */






.box {
  box-sizing: border-box;
  width: 240px;
  height: 60px;
  background-color: #ff6a00;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 2px 5px 0 #A3A3A3;
}

.box::before {
  content: '';
  width: 500px;
  height: 50px;
  opacity: 1;
  background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 100% );
  transform-origin: center center;
  position: absolute;
  z-index: 10;
  animation-delay: 0s;
  animation-name: mask-ani;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.box::after {
  content: '';
  width: 500px;
  height: 50px;
  opacity: 0;
  background-color: #fff;
  transform-origin: center center;
  position: absolute;
  z-index: 1;
  animation-delay: 0s;
  animation-name: mask-border-ani;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.box a {
  width: calc(100% - 8px) !important;
  height: calc(100% - 8px) !important;
  vertical-align: top;
  z-index: 5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes mask-ani {
  0% {
    transform: rotate(-36deg) translate(-40px, -430px);
  }

  70%, 100% {
    transform: rotate(-36deg) translate(-40px, 378px);
  }
}

@keyframes mask-border-ani {
  0% {
    opacity: 0.8;
    transform: rotate(-36deg) translate(-126px, -190px);
  }

  70%, 100% {
    opacity: 0.8;
    transform: rotate(-36deg) translate(-126px, 355px);
  }
}


/*
$anime-time: 8s;

$box-size: 200px;
$clip-distance: .05;
$clip-size: $box-size * (1 + $clip-distance * 2);
$path-width: 2px;

$main-color: #69ca62;

// $codepen-logo-path: url('//blog.codepen.io/wp-content/uploads/2012/06/Button-White-Large.png');

%full-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinning-border {
  @extend %full-fill;
 // width: $box-size;
//  height: $box-size;
//  margin: auto;
  //  background: $codepen-logo-path no-repeat 50% / 70% rgba(#000, .1);
  color: $main-color;
  box-shadow: inset 0 0 0 1px rgba($main-color, .5);

  &::before,
  &::after {
    @extend %full-fill;
    content: '';
    z-index: -1;
    margin: -1 * $clip-distance * 100%;
    box-shadow: inset 0 0 0 $path-width;
    animation: clipMe $anime-time linear infinite;
  }

  &::before {
    animation-delay: $anime-time * -.5;
  }
  // for debug
  &:hover {
    &::after,
    &::before {
      background-color: rgba(#f00, .3);
    }
  }
}

@keyframes clipMe {
  0%, 100% {
    clip: rect(0px, $clip-size, $path-width, 0px);
  }

  25% {
    clip: rect(0px, $path-width, $clip-size, 0px);
  }

  50% {
    clip: rect($clip-size - $path-width, $clip-size, $clip-size, 0px);
  }

  75% {
    clip: rect(0px, $clip-size, $clip-size, $clip-size - $path-width);
  }
} */
