


.analytics {

  &-row {
    padding-bottom: 3px;
  }

}
