.company-payments-disabled-button {
  color: rgb(108, 117, 125);
  // pointer-events: none;
  background-color: transparent;
  border-color: rgb(108, 117, 125);
  opacity: 0.65;
}

.company-payments-highlighted-table-default {
  padding: 8px;
  height: 100%;
}
