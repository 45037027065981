
.new-voucher {
  &-confirmations {
    //  margin-right: calc(31.25% - 100px);


    &-recommended {
      float: right;
      margin-bottom: 2px;
      margin-top: 5px;
      //  margin-right: calc(31.25% - 130px);

      &-text {
        padding-right: 5px;
        color: #7f8fa4;
      }
    }

    &-terms {
      float: right;
      margin-bottom: 10px;
      margin-top: 2px;

      &-text {
        padding-right: 5px;
        color: #7f8fa4;
      }
    }
  }

  &-left {
    margin-top: 20px;
    //  width: 64.66%;
    //  margin-right: 2%;
    //  padding-right: 2%;
    //  border-right: 2px solid #DDD;
    //  margin-top: 40px;
    margin-bottom: 20px;


    &-row {
      margin-bottom: 15px;
      //  position: relative;
      //  top: -20px;

      &-first {
        height: 45px;
      }
    }

    &-input {
      display: inline-block;
      width: calc(100% - 36px);
      box-shadow: none;
      font-size: 14px;
      //  margin-bottom: 10px;
      // margin-top: 2px;
      border-radius: 0 4px 4px 0;
      border-left: 0;
      height: 34px;
      border-width: 1px;
      position: relative;
      top: 0px;
      align-self: flex-end;

      &::placeholder {
        opacity: 0.6;
      }

      &-fullwidth {
        box-shadow: none;
        font-size: 14px;
        align-self: flex-end;

        &::placeholder {
          opacity: 0.6;
        }
      }

      &-col {
        //  font-size: 14px;
        display: flex;
        //  margin-bottom: 5px;
      }

      &-sign {
        display: inline-block;
        background: #6FC0F9;
        color: #FFF;
        //  font-size: 16px;
        width: 34px;
        height: 34px;
        text-align: center;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid transparent;
        border-right: 0;
        position: relative;
        top: 0px;
        align-self: flex-end;

        &-sign {
          display: inline-block;
          color: #FFF;
          font-size: 16px;
          //  line-height: 30px;
          width: 34px;
          height: 34px;
          text-align: center;
          padding-top: 4px;
        }
      }
    }
  }

  &-right {
    margin-top: 20px;


    &-row {
      margin-bottom: 15px;

      &-first {
        height: 45px;
        margin-bottom: 43px;
      }

      &-second {
        height: 45px;
        margin-bottom: 47px;
      }

      & > .col-sm-3 {
        padding-right: 0px;
        padding-left: 5px;
      }
    }

    &-input {
      display: inline-block;
      width: calc(100% - 36px);
      min-width: 64px;
      box-shadow: none;
      font-size: 14px;
      align-self: flex-end;
      border-radius: 0 4px 4px 0;
      border-left: 0;
      height: 34px;
      border-width: 1px;
      position: relative;
      top: 0px;

      &::placeholder {
        opacity: 0.6;
      }

      &-start-date {
        box-shadow: none;
        font-size: 14px;
        align-self: flex-start;
        width: 100%;
        min-width: 100px;

        &::placeholder {
          opacity: 0.6;
        }
      }

      &-fullwidth {
        box-shadow: none;
        font-size: 14px;
        align-self: flex-start;
        width: 100%;
        min-width: 100px;

        &::placeholder {
          opacity: 0.6;
        }
      }

      &-col {
        font-size: 14px;
        display: flex;
        margin-bottom: 5px;
      }

      &-sign {
        display: inline-block;
        background: #6FC0F9;
        color: #FFF;
        //  font-size: 16px;
        //  line-height: 34px;
        width: 34px;
        height: 34px;
        text-align: center;
        border-radius: 4px 0px 0px 4px;
        align-self: flex-end;
        border: 1px solid transparent;
        border-right: 0;
        position: relative;
        top: 0px;

        &-sign {
          display: inline-block;
          color: #FFF;
          font-size: 16px;
          //  line-height: 30px;
          width: 34px;
          height: 34px;
          text-align: center;
          padding-top: 4px;
        }
      }
    }
  }

  &-seperator {
    padding-top: 30px;
    padding-bottom: 40px;

    &-line {
      width: 2px;
      border: 1px solid #DDD;
      margin-left: auto;
      margin-right: auto;
      height: 100%;
    }
  }

  &-label {
    font-size: 12px;
    text-align: left;
    padding: 0;
    margin: 0;
  }

  &-info-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    margin-left: 3px;
    background: #6FC0F9;
    border-radius: 50%;
    font-size: 9px;
    font-weight: 700;
    color: #FFF;
    text-align: center;
  }
}

.voucher-preview {
  background-color: #D3E3F2;
  width: 270px;
  height: 560px;
  color: #637D96;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 0px;

  &-modal {
    width: 270px;
    border-radius: 4px;

    .modal-content {
      padding: 0;
      width: 270px;
      box-shadow: none;
      background: transparent;
    }

    .modal-body {
    }
  }

  &-title {
    color: #03c2fc;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding-top: 10px;
  }

  &-app-top {
    position: relative;
    height: 50px;
    max-height: 50px;
    width: 270px;
    border-bottom: 1px solid #B3C3D2;
    margin: 0;
    margin-bottom: 8px;

    &-logo {
      position: relative;
      height: 24px;
      margin-top: -7px;
      width: auto;
      top: 0;
      line-height: 50px;

      &-wrapper {
        position: relative;
        height: 50px;
        max-height: 50px;
        width: 35%;
        background: #03c2fc;
        color: #FFF;
        display: inline-block;
        top: 0;
        border-radius: 0 0 4px 0;
        padding: 0;
        padding-left: 15px;
      }

      &-text {
        display: inline-block;
        line-height: 50px;
        font-weight: 700;
      }
    }

    &-middle {
      position: relative;
      width: 20%;
      height: 50px;
      max-height: 50px;
      font-weight: 900;
      padding: 0;
      display: inline-block;
      padding-bottom: 8px;

      &-icon {
        padding-left: 12px;
        // font-size: 28px;
        height: 17px;
        margin-top: 13px;

        & img {
          height: 17px;
        }
      }
    }

    &-right {
      position: relative;
      width: 45%;
      height: 50px;
      max-height: 50px;
      display: inline-block;

      &-icon {
        height: 18px;
        margin-top: 13px;
        display: inline-block;
        padding-left: 10px;
        float: right;

        & img {
          height: 18px;
          display: inline-block;
        }
      }
    }
  }

  &-card {
    min-width: 270px;
    max-width: 270px;
    width: 270px;
    margin-right: 35px;
  }

  &-top {
    position: relative;
    //  height: 30%;
    height: 160px;

    &-image {
      //  max-height: 100%;
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 15px 15px 0px 0px;
    }
  }

  &-header {
    position: relative;
    top: -40px;
    //  height: 10%;
    height: 50px;
    border-radius: 15px;
    background-color: #FFF;

    &-logo {
      object-fit: cover;
      width: 40px;
      height: 40px;
      margin: 5px;
      //  margin-top: 0;
      display: inline-block;
      border-radius: 8px;
    }

    &-text {
      display: inline-block;
      height: 50px;
      position: absolute;
      width: calc(100% - 70px);
    }

    &-name {
      padding-top: 8px;
      font-size: 14px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-website {
      font-size: 10px;
      color: #03c2fc;
      text-decoration: underline;
    }
  }

  &-body {
    padding-top: 8px;
    position: relative;
    top: -40px;
    //  height: 10%;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //  display: -webkit-box;
    //  line-height: 16px;
    //  max-height: 32px;

    &-description {
      //  height: 10%;
      padding-left: 15px;
      padding-right: 15px;
      text-align: justify;
      line-height: 16px;
      font-size: 12px;

      &-hidden {
        //  height: 10%;
        overflow: hidden;
        font-size: 13px;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 16px;
        max-height: 32px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 15px;
        padding-right: 15px;
        text-align: justify;
      }
    }

    &-more {
      margin-left: 70%;
      width: 30%;
      line-height: 16px;
      font-size: 10px;
      font-weight: 700;
      position: relative;
      right: 0;
      padding-left: 15px;
      padding-right: 15px;
      color: #637D96;

      &:hover {
        color: #637D96;
      }

      &:focus {
        color: #637D96;
      }
    }
  }

  &-steps {
    position: relative;
    padding-top: 10px;

    &-1 {
      display: inline-block;
      width: 25%;
      margin-left: 5%;
      margin-right: 6%;
      background-color: #D3E3F2;
      //  box-shadow: inset 4px 4px 8px #BBCFE2, inset -5px -5px 30px #EEF7FF;
      box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
      border-radius: 15px;
      z-index: 10;
      position: relative;
      cursor: pointer;
      z-index: 300;
    }

    &-2 {
      display: inline-block;
      width: 25%;
      margin-right: 7%;
      background-color: #D3E3F2;
      box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
      border-radius: 15px;
      z-index: 10;
      position: relative;
      cursor: pointer;
      z-index: 500;
    }

    &-3 {
      display: inline-block;
      width: 25%;
      background-color: #D3E3F2;
      box-shadow: 5px 5px 4px rgba(173, 189, 204, 0.8), -5px -5px 4px rgba(242, 249, 255, 0.8);
      border-radius: 15px;
      z-index: 10;
      position: relative;
      cursor: pointer;
      z-index: 300;
    }

    &-active {
      // box-shadow: -5px -5px 7px rgba(242, 249, 255, 0.8), 5px 5px 7px rgba(173, 189, 204, 0.8);
      box-shadow: 5px 5px 7px #EEF7FF, -4px -4px 4px #BBCFE2;
    }

    &-percent {
      font-size: 15px;
      width: 100%;
      font-weight: 700;
      line-height: 18px;
      padding-top: 1px;
      text-align: center;
      //  padding-top: 1px;
    }

    &-name {
      font-size: 13px;
      width: 100%;
      font-weight: 500;
      text-align: center;
    }

    &-image {
      position: absolute;
      z-index: 400;
      pointer-events: none;
      top: -5px;
      width: 88%;
      margin-left: 14px;
      margin-right: 10%;
    }
  }

  &-loyalty-text {
    top: -40px;
    position: relative;
    font-weight: 700;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &-vouchers {
    margin-top: 25px;
    position: relative;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;

    &-left {
      position: relative;
      width: 100%;
      height: 50px;
      border-radius: 15px;
      background-color: #D3E3F2;
      //  box-shadow: -3px -3px 4px white, 5px 5px 4px #ccc;
      box-shadow: 3px 3px 7px rgba(173, 189, 204, 1), -3px -3px 7px rgba(242, 249, 255, 1);
      padding-top: 12px;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;

      &-1 {
        display: inline-block;
        width: 23%;
        font-size: 16px;
        font-weight: 700;
        align-self: center;
      }

      &-2 {
        display: inline-block;
        width: 23%;
        font-size: 9px;
        font-weight: 700;
        align-self: center;
      }
    }

    &-right {
      position: absolute;
      right: 0;
      top: 0;
      width: 46%;
      border-radius: 15px;
      background-color: #03c2fc;
      padding-top: 12px;
      padding-bottom: 10px;
      height: 50px;
      //  padding-bottom: 6px;
      padding-left: 1px;
      padding-right: 1px;
      display: flex;
      // box-shadow: inset 3px 3px 7px #78CEFF, inset -3px -3px 7px rgba(19, 0, 247, 0.2);

      &-1 {
        display: inline-block;
        width: 49%;
        font-size: 9px;
        font-weight: 700;
        color: white;
        text-align: center;
        align-self: center;
        //  padding-left: 10px;
        position: relative;
        left: 7px;
        //  padding-top: 12px;

        &-long {
          display: inline-block;
          width: 49%;
          font-size: 9px;
          font-weight: 700;
          color: white;
          text-align: center;
          align-self: center;
          //  padding-left: 10px;
          position: relative;
          left: 7px;
        }
      }

      &-2 {
        display: inline-block;
        width: 51%;
        font-size: 16px;
        font-weight: 200;
        color: white;
        text-align: center;
        align-self: center;
        //  margin-top: -2px;
        //  padding-top: 8px;

        &-long {
          //  top: -6px;
          //  position: absolute;
          font-size: 16px;
          //  padding-right: 1px;
          text-align: center;
          align-self: center;
        }
      }
    }
  }

  &-voucher {
    width: 100%;
    height: 55px;
    position: relative;
    margin-top: 4px;
  }

  &-footer {
    top: -40px;
    position: relative;
  }
}
