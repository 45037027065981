/* BORDER RADIUS STYLES
   ----------------------------- */

.b-radius {
	border-radius: 4px;
    overflow: hidden;
}
.b-circle {
    border-radius: 500px;
    overflow: hidden;
}