.nav-item {


  &-counter {
    float: right;
  //  background-color: #FFF;
    border-radius: 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 15px;
    top: 15px;
  //  color: #0b1628;
    text-align: center;
    font-size: 16px;
    padding-top: 1px;

    background-color: #ff6a00;
    color: #FFF;

  }

  &-withCounter {

    & a {
    //  background: #ff6a00!important;
    }

  }
}
