
.perks {
  &-card {
    background: #FCFCFC;
    border: 1px solid #EEEEEE;
    box-shadow: 5px 5px 5px #EEEAE6;
    cursor: pointer;

    &-image {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      // object-fit: contain;
      aspect-ratio: 16/10
    }
  }

  &-modal {
    &-image {
      max-width: 150px;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      object-fit: contain;
      aspect-ratio: 1;
    }
  }
}
