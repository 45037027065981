.permissions-levels {

  &-table {
    font-size: 11px;

    & .rs-table-cell-header {

      & .rs-table-cell-content {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }

  &-checkbox {
    width: 25px;
    height: 25px;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    border-width: 2px;

    &:checked {
      background-color: green;
    }
  }

  &-create {

    &-highlighted {
      font-weight: bold;
    }

    &-padded {
      padding-left: 35px;
    }
  }
}
