.campaign-actions {

  &-modal {

    & .modal-header {
      & h5 {
        font-weight: 700;
      }
    }

    &-body {
      padding-top: 0px;
    }

    &-header {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

}

.admin-campaigns-table {
  .rs-table-cell-header {
    .rs-table-cell-content {
      display: flex;
      align-items: center;
      // justify-content: center;
      padding: 8px;
    }

    /*
    .rs-table-column-group {
      .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
    } */
  }

  /*
  .rs-table-column-group {
    .rs-table-cell-header {
      .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
      }
    }
  } */

  .rs-table-column-group-header-content {
    // text-align: center;
    padding: 5px;
  }

  .rs-table-cell-content {
    padding: 0;
  }

  .rs-table-cell-wrap {
    height: 100%;
  }

  &-highlight {
    background-color: #E3F6FF;
    padding: 8px;
    height: 100%;
  }

  &-default {
    background-color: #ffffff;
    padding: 8px;
    height: 100%;
  }
}