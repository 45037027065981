

.live-metrics {
//  padding-bottom: 35px;

  & .recharts-legend-item-text {
    font-size: 11px;
    white-space: nowrap;
  }

  & .recharts-bar-rectangle {
    width: 5px;
    max-width: 5px;

    & path {
      width: 5px;
      max-width: 5px;
    }
  }

  & .recharts-label-list {
    font-size: 10px;
    margin-bottom: -5px;
    position: relative;
    top: -2px;

    & text {
      line-height: 20px;
    }
  }

  &-export-button {
    background: #00C116;
    background-color: #00C116;
    float: right;
    color: #000;
    font-size: 9px;
    z-index: 999;
    position: relative;
    right: -25px;
    bottom: -5px;
    max-width: 20%;
    padding: 3px;

    &:focus {
      background: #00C116;
      background-color: #00C116;
      color: #000;
    }

    &:active {
      background: #00C116;
      background-color: #00C116;
      color: #000;
    }

    &:hover {
      background: #00C116;
      background-color: #00C116;
      color: #000;
    }
  }

  &-card {
    padding-bottom: 0px;
  }

  .recharts-surface {
    overflow: visible;
  }

  &-circle {
    width: 60%;
    margin-right: 20%;
    margin-left: 20%;

    &-lenght {
      .CircularProgressbar-text {
        font-size: 0.9vw;
      }
    }
  }

  &-filters {
    padding-bottom: 20px;

    & .date-picker {

      & input {
        width: 100%;
      }

      & button {
      //  width: 25%;
      }
    }
  }

  & .live-metric {
    display: block;
    text-align: center;
    padding-right: 0;

    &-circle {
    //  width: 60%;
    //  margin-right: 20%;
    //  margin-left: 20%;

      &-long {
        .CircularProgressbar-text {
          font-size: 0.9vw;
        }
      }
    }
  }

}

.campaign-details {
  max-height: calc(100vh - 245px);
  overflow-y: scroll;
  overflow-x: hidden;

  &-section {
    background-color: #f2f5f9;
    margin-bottom: 15px;
    padding: 30px;
  }

  &-footer {
    width: 100%;

    & .campaign-details-button {
      margin-top: 15px;
      float: right;
      margin-left: 15px;
      margin-right: 10px;
    }
  }
}
