/* BODY STYLES
   ----------------------------- */

// .cancel-hover {
//  background-color: transparent!important;
//  box-shadow: none!important;
// }

.company-tab-change-button {
  width: 10%;
  min-width: fit-content;
  max-width: 300px;
  margin-right: 5px;


  &-active {
    background: rgb(0, 174, 255);
    background-color: rgb(0, 174, 255) !important;
  }
}

.centered-modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.status-indicators {
  &-active {
    width: 14px;
    height: 14px;
    margin-top: 10px;
    border-radius: 50%;
    background-color: #5fbc39;
  }

  &-inactive {
    width: 14px;
    height: 14px;
    margin-top: 10px;
    border-radius: 50%;
    background-color: #f3292c;
  }

  &-yellow {
    width: 14px;
    height: 14px;
    margin-top: 10px;
    border-radius: 50%;
    background-color: #Ffdd44;
  }
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f3292c;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5fbc39;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

input:disabled + .slider {
  background-color: #989ba3;
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}



/*
.custom-checkbox input {
  position: relative;
  z-index: -9999;
}

.custom-checkbox span {
  width: 25px;
  height: 25px;
  display: block;
  background: #d2d2d2;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  border-width: 2px;
}

.custom-checkbox input:checked + span {
  background: #5ebc39;
}  */

/* Customize the label (the container) */
.custom-checkbox {
  display: block;
  position: relative;
//  padding-left: 35px;
//  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: #eee;
  border-radius: 5px;
  border: 2px solid #ccc;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #5ebc39;
}

.custom-checkbox input:disabled ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
//  display: none;
  display: block;
  opacity: 0;
}


/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
  opacity: 1;
}

.custom-checkbox input:disabled ~ .checkmark:after {
  display: block;
  opacity: 0.7;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.roles-select {

  &-row {
    margin-bottom: 15px;
  }

  &-input {

    & > input {
      &:checked:after {
        background-color: #5fbc39;
    }
    }
  }

  &-name {

  }

  &-description {

  }
}



.btn-link {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}

.rw-input[disabled] {
  background-color: #e9ecef;
}

video {background-color: transparent}

.has-submenu {
  & .has-submenu-hover {
    opacity: 1;

    &:hover {
      opacity: 0.85;
    }
  }
}

.drag-order-toggle {
  padding: 0px;
  width: 30px;
  height: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 0;
}

.text-read-more {
  font-size: 6px;
  font-weight: 600;
  float: right;
  position: relative;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    text-decoration: none;
  }

  &-wrapper {
    padding-top: 10px;
    position: relative;
  }

  &-text {
    padding-left: 15px;
    padding-right: 15px;
    text-align: justify;
    line-height: 16px;

    &-hidden {
    //  height: 10%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 16px;
      max-height: 48px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      padding-left: 15px;
      padding-right: 15px;
      text-align: justify;
    }
  }
}



.disabled {
  cursor: default!important;
}

.display-inline {
  display: inline;
}

.row-disabled {
  color: #7f8fa4;
}

.rs-table-cell {
  background: transparent;
}

.rs-table-cell-content {
  white-space: normal;
  word-wrap: break-word;
  min-width: min-content;

  & .btn {
    padding: 0;
    font-size: 14px;
    border: 0;
    white-space: nowrap;
    vertical-align: inherit;
  }
}

.company-dashboard-campaigns-table {
  &-toggle {
    position: absolute;
    right: 17px;
    bottom: 41px;
    z-index: 999;
    width: 25px;
    height: 25px;
    padding: 0px;
   padding-bottom: 8px;
    font-weight: bold;
    padding-top: 0px;
    text-align: center;
    // line-height: 25px;
  }
}

.rs-table-row-header {
  text-align: center;

//  border-right: 1px solid #eee;
//  border-left: 1px solid #eee;

  & .rs-table-cell-content {

    & div {
    //  padding: 0;
    //  display: inline;
      word-break: break-word;
    }

  }

  & .rs-table-cell {
    padding: 2px 0px!important;
  }

  & .rs-table-column-group-cell {
    & .rs-table-cell {
      padding: 0!important;
    }
  }
}

.rs-table-cell-header-sort-wrapper {
  display: inline;

  .rs-table-cell-header-icon-sort {
    &:after {
      content: '';
    }

    &-asc {
      &:after {
        content: '\23F6';
      }
    }

    &-desc {
      &:after {
        content: '\23F7';
      }
    }
  }
}

.rs-table-column-group-header {
//  max-height: 30px!important;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}

.rs-table-row-header .rs-table-cell {
//  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}

.rs-table-column-group-header-content {
//  padding: 5px;
//  text-align: center;
//  height: 30px !important;
//  max-height: 30px!important;
//  padding-top: 4px;
//  padding-bottom: 4px;
//  border-right: 1px solid #7f8fa4;
}

.rs-table-body-row-wrapper {
  overflow: unset;
}

.draggable {
  &-container {
    height: 700px;
    position: relative;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    overflow: hidden;

    & .resize-handle {
      width: 13px;
      height: 13px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      border: 1px solid;
      line-height: 0.7;
      background: #FFF;
      z-index: 900;
      pointer-events: auto;
    }

    & .handle {
      width: 13px;
      height: 13px;
      position: absolute;
      top: 0px;
      left: 0px;
      border: 1px solid;
      line-height: 0.7;
      background: #FFF;
      z-index: 900;
      pointer-events: auto;
    }
  }

  &-element {
    display: inline-block;
    position: absolute;
    z-index: 100;
    //  pointer-events: none;

    &-image {
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  &-active {
    border: 2px solid red;
    pointer-events: none;
  }

  &-not-active {
    border: 2px solid #000;
  }

  &-edit {
    &-new {
      //  padding-bottom: 10px;
      //  border-bottom: 1px solid #ddd;
    }

    &-border-bottom {
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }

    &-active {
      border: 1px dotted;
    }

    &-button {
      width: 100%;
      font-size: 12px;
      padding-left: 2px;
      padding-right: 2px;

      &-wrapper {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &-color-button {
      width: 25px;
      height: 25px;
      display: inline-block;
      position: relative;
      top: 7px;
    }
  }
}

.react-tooltip-custom-class {
  max-width: 250px;
  z-index: 1500 !important;

  &-company-payment {
    max-width: 350px;
  }
  //  position: relative!important;
  //  top: 0!important;
  //  left: -20px!important;
}

.react-tooltip-company-error {
  max-width: fit-content;
  width: inherit;
  min-width: 80px;
  z-index: 1500 !important;
  padding: 1px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  top: 2px !important;
  //  position: relative!important;
  //  top: 0!important;
  //  left: -20px!important;
}

.mandatory {
  color: red;
  display: inline-block;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

.modal-max-height-page {
  max-height: 93vh;
//  height: 93vh;
  overflow-x: hidden;
}

.pointer {
  cursor: pointer!important;
}

.body {
  &-notification {

    &-close {
      float: right;
      background: transparent;
      color: #FFF;
      border: 0;
      padding-top: 0;
      box-shadow: none;
      cursor: pointer;
    }
  }
}

.card {

  .body {
    &-title {
      display: inline-block;
      margin-top: 7px;
    }

    &-add-button {
      margin-bottom: 10px;
      margin-left: 10px;
    }

    &-filters {
      margin-bottom: 10px;
    }
    /*  &-notification {

      &-close {
        float: right;
        background: transparent;
        color: #FFF;
        border: 0;
        padding-top: 0;
        box-shadow: none;
        cursor: pointer;
      }
    } */
  }

  & .filter-button {
    float: right;
    margin-left: 15px;
  }

  & .filter-button-active {
    color: #fff;
    background-color: #6c757d;
    background: #6c757d;
    border-color: #6c757d;

    &:focus {
      color: #fff;
      background-color: #6c757d;
      background: #6c757d;
      border-color: #6c757d;
    }

    &:active {
      color: #fff;
      background-color: #6c757d;
      background: #6c757d;
      border-color: #6c757d;
    }

    &:hover {
      color: #fff;
      background-color: #6c757d;
      background: #6c757d;
      border-color: #6c757d;
    }
  }

  .admin-table {

    & th {
      padding: 10px 5px;
      font-size: 11px;
      cursor: default;
      min-width: 70px;
    }

    & td {
      padding: 10px 5px;
      font-size: 11px;
      cursor: default;
      min-width: 70px;
    }

    & button {
      font-size: 13px;
    }

    &-chevron {
      width: 20px;
      height: 20px;
    }

    &-name {
      font-size: 14px;
      text-transform: capitalize;
      line-height: 1;
      margin-bottom: 0px;
    }

    &-role {
      font-size: 10px;
      text-transform: capitalize;
      text-align: right;
      margin-bottom: 0px;
    }

    &-new-user {
      background: #edea98 !important;
    }

    &-unverified-user {
      background: #f6de7b !important;
    }

    &-read-more {
      //  margin-left: 40%;
      //  width: 30%;
      //  line-height: 16px;
      font-size: 6px;
      font-weight: 600;
      float: right;
      position: relative;
      right: 0;
      padding-left: 10px;
      padding-right: 10px;

      &:hover {
        text-decoration: none;
      }

      &-wrapper {
        padding-top: 10px;
        position: relative;
      }

      &-text {
        padding-left: 15px;
        padding-right: 15px;
        text-align: justify;
        line-height: 16px;

        &-hidden {
          //  height: 10%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-height: 16px;
          max-height: 32px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          padding-left: 15px;
          padding-right: 15px;
          text-align: justify;
        }
      }
    }

    &-company-api {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      margin-left: auto;
      margin-right: auto;

      &-active {
        background: green;
      }
    }

    &-company-owner {
      font-weight: 500;

      & td {
        padding: 10px 5px;
        font-size: 12px;
      }
    }

    &-company-member {
      font-weight: 500;

      &-odd {
        background-color: #f6f7fb !important;
      }

      &-even {
        background-color: #fff !important;
      }
    }

    & .uid-column {
      font-size: 10px;
    }

    & .website-column {
      position: relative;
      min-width: 100px;

      &-input {
        position: absolute;
        //  width: 90%;

        &-wrapper {
          position: relative;
        }
      }

      &-save {
        position: absolute;
        right: 20px;
        width: 20px;
        height: 20px;
        padding: 0px;
        margin-top: 9px;
      }

      &-close {
        position: absolute;
        right: 0px;
        width: 20px;
        height: 20px;
        padding: 0px;
        margin-top: 9px;
      }

      &-open {
        width: 100%;
        position: absolute;
        height: 100%;
        padding: 0;
        top: 0;
        left: 0;
      }

      &-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;

        &-check {
          color: #2FE31A;
        }

        &-x {
          color: red;
        }
      }
    }

    &-more-button {
      display: inline-block;
      margin-right: 15px;
      margin-left: 15px;
      min-width: 100px;
      margin-top: 20px;
    }
  }



  .content-management-text-area {
    height: 450px;
  }

  & .editable-column {
    position: relative;
    min-width: 150px;

    &-input {
      position: absolute;
      //  width: 90%;

      &-wrapper {
        position: relative;
      }
    }

    &-save {
      position: absolute;
      right: 20px;
      width: 20px;
      height: 20px;
      padding: 0px;
      margin-top: 9px;
    }

    &-close {
      position: absolute;
      right: 0px;
      width: 20px;
      height: 20px;
      padding: 0px;
      margin-top: 9px;
    }

    &-open {
      width: 100%;
      position: absolute;
      height: 100%;
      padding: 0;
      top: 0;
      left: 0;
    }

    &-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;

      &-check {
        color: #2FE31A;
      }

      &-x {
        color: red;
      }
    }
  }
}

.content-management-video {
  padding: 0;
  top: 10vh;

  & .modal-content {
    padding: 0;
    background: transparent;
  }

  & .modal-header {
    display: none;
  }

  & .modal-body {
    padding: 0;
  }

  & .modal-footer {
    display: none;
  }
}
